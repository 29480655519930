import { MAX_FILE_SIZE } from 'constants/constants'
import cookie from 'cookie'
import cookies from 'js-cookie'
import moment from 'moment'
import sha256 from 'crypto-js/sha256'

function secondsToHoursMinSec(time) {
  const days = Math.floor(time / (1000 * 60 * 60 * 24))
  const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((time % (1000 * 60)) / 1000)

  return [days, hours, minutes, seconds]
}

function numberWithCommas(x) {
  if (!x) {
    return
  }
  const decimalArray = x.toString().split('.')
  decimalArray[0] = decimalArray[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return decimalArray.join('.')
}

function parseCookies(req) {
  if (req) {
    return cookie.parse(req.headers.cookie || '')
  }

  if (typeof window !== 'undefined') {
    return cookie.parse(window.document.cookie)
  }
  return cookie.parse('')
}

const setCookieUtil = (key, value) => {
  cookies.set(key, value, {
    expires: 100000000
  })
}

const removeCookieUtil = (key) => {
  cookies.remove(key)
}

const setStorage = async (key, value) => {
  try {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    console.error(error)
  }
}

const getStorage = async (key) => {
  try {
    window.sessionStorage.getItem(key)
  } catch (error) {
    console.error(error)
  }
}

const checkIsLocked = (status) => {
  switch (status) {
    case 'pending':
      return false
    case 'active':
      return false
    case 'inactive':
      return true
    case 'terminated':
      return true
    case 'registered':
      return false
  }
}

const formateDate = (date, format) => {
  const dateFormat = format ? format : 'DD/MM/YYYY'
  const date1 = moment(new Date(date)).format(dateFormat)
  return date1
}

const formateDateTime = (date, format) => {
  const dateFormat = format ? format : 'DD/MM/YYYY HH:mm:ss'
  const date1 = moment(new Date(date)).format(dateFormat)
  return date1
}

const filterDataHaveValue = (data) => {
  return Object.fromEntries(
    Object.entries(data).filter(
      ([key, value]) => value !== 'all' && value !== undefined && value !== null && value !== ''
    )
  )
}

const checkFileSizeValid = (file) => {
  if (file) {
    if (file.size / 1024 <= MAX_FILE_SIZE) {
      return true
    } else {
      return false
    }
  }
}

const getParentId = (value) => {
  switch (value.type) {
    case 'industrySector':
      return value.industry
    case 'category':
      return value.industrySector
    case 'model':
      return value.category
    default:
      return 0
  }
}

const sha256Password = (input) => {
  if (input) {
    const hash = sha256(input)
    return hash.toString()
  }
}

function formatWithCommas(value) {
  if (!value) return String(value)
  const parts = value.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

function randomDate(start, end) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
}

export {
  secondsToHoursMinSec,
  numberWithCommas,
  parseCookies,
  setCookieUtil,
  removeCookieUtil,
  checkIsLocked,
  formateDate,
  filterDataHaveValue,
  checkFileSizeValid,
  getParentId,
  sha256Password,
  formatWithCommas,
  randomDate,
  formateDateTime,
  setStorage,
  getStorage
}
