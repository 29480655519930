import React, { useState, useEffect } from 'react'
import { Container } from 'reactstrap'

// Formik Validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import withRouter from 'components/Common/withRouter'

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb'

// actions
import { editProfile, getProfile, profileSuccess } from '../../store/actions'
import { profileVN } from 'constants/vn'
import UserProfileView from './user-profile-view'
import { getZoneListAll, myLocationManagementAPI, postImage, putProfileAPI } from 'helpers/backend_helper'
import { REGEX_EMAIL } from 'constants/regex'
import { toast } from 'react-toastify'

const UserProfile = (props) => {
  //meta title
  document.title = 'Profile | HPG'

  const isEdit = props.router.location.pathname.includes('edit')
  const dispatch = useDispatch()
  const selectProfileState = (state) => state.Profile

  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    error: profile.error,
    success: profile.success,
    profile: profile.profile
  }))

  const { profile } = useSelector(ProfileProperties)
  const [myLocationManagement, setMyLocationManagement] = useState([])
  const navigate = props.router.navigate

  useEffect(() => {
    dispatch(getProfile())
  }, [dispatch])

  useEffect(() => {
    handleGetMyLocationManagement()
  }, [])

  const handleGetMyLocationManagement = async () => {
    try {
      const res = await myLocationManagementAPI()
      if (res.data) {
        setMyLocationManagement(res.data)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleAcceptedFiles = async (file, name) => {
    let formData = new FormData()
    formData.append('file', file)

    try {
      const res = await postImage(formData)
      if (res) {
        const resUpdateProfile = await putProfileAPI({
          image: `${res.data.data.pre + res.data.data.path}`
        })
        dispatch(getProfile())
        toast(resUpdateProfile.message, { type: 'success' })
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const hanleRemoveAvatar = async () => {
    try {
      const resUpdateProfile = await putProfileAPI({
        image: ''
      })
      dispatch(getProfile())

      toast(resUpdateProfile.message, { type: 'success' })
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title='Menu' breadcrumbItem={profileVN.title} />
          <UserProfileView
            data={profile}
            myLocationManagement={myLocationManagement}
            onUpdateImage={handleAcceptedFiles}
            onRemoveImage={hanleRemoveAvatar}
            isEdit={isEdit}
            navigate={navigate}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
