import React, { useEffect, useRef, useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

// //Import Scrollbar
import SimpleBar from 'simplebar-react'

// MetisMenu
import MetisMenu from 'metismenujs'
import withRouter from 'components/Common/withRouter'
import { Link } from 'react-router-dom'

//i18n
import { withTranslation } from 'react-i18next'

import PermissionWrapper from 'components/Common/PermissionWrapper'
import menuList from 'routes/menu-list'

const SidebarContent = (props) => {
  const ref = useRef()
  const activateParentDropdown = useCallback((item) => {
    item.classList.add('active')
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show')
    }

    if (parent) {
      parent.classList.add('mm-active')
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add('mm-show') // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add('mm-active') // li
          parent3.childNodes[0].classList.add('mm-active') //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add('mm-show') // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add('mm-show') // li
              parent5.childNodes[0].classList.add('mm-active') // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains('active')) {
        item.classList.remove('active')
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1] ? parent.childNodes[1] : null
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.remove('mm-show')
        }

        parent.classList.remove('mm-active')
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove('mm-show')

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove('mm-active') // li
            parent3.childNodes[0].classList.remove('mm-active')

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove('mm-show') // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove('mm-show') // li
                parent5.childNodes[0].classList.remove('mm-active') // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById('side-menu')
    const items = ul.getElementsByTagName('a')
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName.split('/')[1] === items[i].pathname.split('/')[1]) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu('#side-menu')
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className='h-100' ref={ref}>
        <div id='sidebar-menu'>
          <ul className='metismenu list-unstyled' id='side-menu'>
            {menuList.map((menu, idx1) => {
              if (!menu.subMenu) {
                if (menu.permissionCode === undefined) {
                  return (
                    <li key={idx1}>
                      <Link to={menu.link}>
                        <i className={menu.icon}></i>
                        <span>{menu.name}</span>
                      </Link>
                    </li>
                  )
                }

                return (
                  <PermissionWrapper key={idx1} code={menu.permissionCode}>
                    <li key={idx1}>
                      <Link to={menu.link}>
                        <i className={menu.icon}></i>
                        <span>{menu.name}</span>
                      </Link>
                    </li>
                  </PermissionWrapper>
                )
              }
              return (
                <PermissionWrapper key={idx1} code={menu?.permissionCode} condition='OR'>
                  <li key={idx1}>
                    <Link to='/#' className='has-arrow'>
                      <i className={menu.icon}></i>
                      <span>{menu.name}</span>
                    </Link>
                    <ul className='sub-menu'>
                      {menu.subMenu.map((sub, idx2) => {
                        if (sub.permissionCode === undefined) {
                          return (
                            <li key={`${idx1}-${idx2}`}>
                              <Link to={sub.link}>
                                <span>{sub.name}</span>
                              </Link>
                            </li>
                          )
                        }

                        return (
                          <PermissionWrapper key={`${idx1}-${idx2}`} code={sub.permissionCode}>
                            <li>
                              <Link to={sub.link}>
                                <span>{sub.name}</span>
                              </Link>
                            </li>
                          </PermissionWrapper>
                        )
                      })}
                    </ul>
                  </li>
                </PermissionWrapper>
              )
            })}

            <br></br>
            <li>
              <i className='mdi-amazon-clouddrive'></i>
              <a
                href='https://docs.google.com/document/d/1G8dcNq9cWK1Rz1r4kxoC83Z3defdFMyr/edit#heading=h.gjdgxs'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span>Hướng dẫn sử dụng ứng dụng CMS</span>
              </a>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
}

export default withRouter(withTranslation()(SidebarContent))
