import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { ProductActivationVN, PortfolioVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import {
  deleteProductActivationAPI,
  patchLockProductActivationAPI,
  importFileAPI,
  exportProductActivationAPI,
  downloadTemplateAPI,
  checkUploadFileAPI,
  exportFileError,
  checkExportFileAPI
} from 'helpers/backend_helper'
import {
  ProductActivationIndustrySector,
  ProductActivationIndustry,
  StatusProductActivation,
  ProductActivationCode,
  ProductActivationTime
} from './ManageProductActivationCol'
import AddEditProductActivation from './components/AddEditProductActivation'
import {
  getProductActivationDetailSuccess,
  getProductActivationList,
  setFilter,
  setTypePopupProductActivation
} from 'store/actions'
import ImportModal from 'components/Common/ImportModal'
import * as url from 'helpers/url_helper'
import moment from 'moment'
import { PRODUCT_ACTIVATION_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import usePermission from 'hooks/usePermission'
import { has } from 'lodash'
import PermissionWrapper from 'components/Common/PermissionWrapper'

function ManageProductActivation() {
  //meta title
  document.title = 'Product Activation List | HPG'
  const screenCode = useMemo(() => SCREEN_CODE.PRODUCT_ACTIVATION_MANAGEMENT, [])
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectProductActivationsState = (state) => state.ProductActivationReducer
  const ProductActivationsProperties = createSelector(selectProductActivationsState, (reducer) => ({
    productActivations: reducer.productActivations,
    loading: reducer.loading,
    total: reducer.total
  }))

  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))
  const { profile } = useSelector(ProfileProperties)

  const { productActivations, loading, total } = useSelector(ProductActivationsProperties)

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (layout) => ({
    filter: layout.filter
  }))
  const { filter } = useSelector(LayoutProperties)

  const [isLoading, setLoading] = useState(loading)
  const [isLoadingImport, setLoadingImport] = useState(false)
  const [showDeleteAccount, setShowDeleteAccount] = useState(false)
  const [showLockAccount, setShowLockAccount] = useState(false)
  const [selected, setSelected] = useState()
  const [showForm, setShowForm] = useState(false)
  const [showImport, setShowImport] = useState(false)
  const [page, setPage] = useState(1)
  const hasPermission = usePermission({
    screenCode,
    code: PRODUCT_ACTIVATION_MANAGEMENT_CODE.PRODUCT_DETAIL_PRODUCT_ACTIVATION
  })

  useEffect(() => {
    dispatch(getProductActivationList({ page: 1 }))
  }, [dispatch])

  const onClickDelete = (item) => {
    setSelected(item)
    setShowDeleteAccount(true)
  }
  const onClickLock = (item) => {
    setSelected(item)
    setShowLockAccount(true)
  }
  const onClickItem = async (item, type) => {
    setSelected(item)
    dispatch(setTypePopupProductActivation(type))
    dispatch(getProductActivationDetailSuccess(item))
    setShowForm(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: ProductActivationVN.table.code,
        accessor: 'code',
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ProductActivationCode {...cellProps} />
        }
      },
      {
        Header: ProductActivationVN.table.industry,
        accessor: 'industrySector.parent',
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ProductActivationIndustry {...cellProps} />
        }
      },
      {
        Header: ProductActivationVN.table.industrySector,
        accessor: 'industrySector',
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ProductActivationIndustrySector {...cellProps} />
        }
      },
      {
        Header: ProductActivationVN.table.date,
        accessor: 'startApplyDate',
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ProductActivationTime {...cellProps} />
        }
      },
      {
        Header: ProductActivationVN.table.status,
        accessor: 'status',
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <StatusProductActivation {...cellProps} />
        }
      },
      {
        Header: ProductActivationVN.table.action,
        accessor: 'action',
        disableSortBy: true,
        show: hasPermission,
        hideSort: true,
        Cell: (cellProps) => {
          return (
            <ul className='list-unstyled hstack gap-1 mb-0'>
              <PermissionWrapper
                screenCode={screenCode}
                code={PRODUCT_ACTIVATION_MANAGEMENT_CODE.PRODUCT_UPDATE_PRODUCT_ACTIVATION}
              >
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <button
                    className={`btn btn-sm btn-soft-info ${
                      cellProps.row.original?.status === 'activate' && 'opacity-50'
                    }`}
                    id={`edittooltip-${cellProps.row.original.id}`}
                    onClick={() => {
                      if (cellProps.row.original?.status !== 'activate') {
                        onClickItem(cellProps.row.original, 'edit')
                      }
                    }}
                  >
                    <i className='mdi mdi-pencil-outline' />
                  </button>
                  <UncontrolledTooltip
                    trigger='hover'
                    placement='top'
                    target={`edittooltip-${cellProps.row.original.id}`}
                  >
                    {cellProps.row.original?.status === 'activate' ? 'Đã kích hoạt không thể chỉnh sửa' : 'Sửa'}
                  </UncontrolledTooltip>
                </li>
              </PermissionWrapper>
              <PermissionWrapper
                screenCode={screenCode}
                code={PRODUCT_ACTIVATION_MANAGEMENT_CODE.PRODUCT_DELETE_PRODUCT_ACTIVATION}
              >
                <li>
                  <button
                    className={`btn btn-sm btn-soft-danger ${
                      cellProps.row.original?.status === 'activate' && 'opacity-50'
                    }`}
                    onClick={() => {
                      if (cellProps.row.original?.status !== 'activate') {
                        const item = cellProps.row.original
                        onClickDelete(item)
                      }
                    }}
                    id={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    <i className='mdi mdi-delete-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`deletetooltip-${cellProps.row.original.id}`}
                    >
                      {cellProps.row.original?.status === 'activate' ? 'Đã kích hoạt không thể xóa' : 'Xóa'}
                    </UncontrolledTooltip>
                  </button>
                </li>
              </PermissionWrapper>
              <PermissionWrapper
                screenCode={screenCode}
                code={PRODUCT_ACTIVATION_MANAGEMENT_CODE.PRODUCT_CHANGE_STATUS_PRODUCT_ACTIVATION}
              >
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <button
                    className={`btn btn-sm btn-soft-warning ${
                      cellProps.row.original?.status === 'activate' && 'opacity-50'
                    }`}
                    id={`locktooltip-${cellProps.row.original.id}`}
                    onClick={() => {
                      if (cellProps.row.original?.status !== 'activate') {
                        onClickLock(cellProps.row.original)
                      }
                    }}
                  >
                    <i className='mdi mdi-lock-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`locktooltip-${cellProps.row.original.id}`}
                    >
                      {cellProps.row.original?.status === 'activate'
                        ? 'Đã kích hoạt không thế khóa'
                        : cellProps.row.original.status !== 'temporary_deleted'
                        ? 'khóa'
                        : 'mở khóa'}
                    </UncontrolledTooltip>
                  </button>
                </li>
              </PermissionWrapper>
            </ul>
          )
        }
      }
    ],
    [hasPermission]
  )
  const permissionColumns = useMemo(() => columns.filter((column) => column.show !== false), [columns])

  const setGlobalFilterUser = async (data) => {
    dispatch(
      setFilter({
        ...data,
        search: data?.search_key,
        page: page
      })
    )

    dispatch(
      getProductActivationList({
        ...data,
        search: data?.search_key,
        meta: {
          'Nhập từ khóa tìm kiếm': data?.search_key,
          'Ngành hàng': data?.industry_sector_names,
          'Trạng thái': data?.status_name
        }
      })
    )
    setPage(1)
  }

  const goToPage = (page) => {
    dispatch(getProductActivationList({ ...filter, page }))
    dispatch(setFilter({ ...filter, page: page }))
    setPage(page)
  }

  const onDeleteAccount = async () => {
    try {
      const res = await deleteProductActivationAPI({
        id: selected.id
      })
      if (res) {
        toast('Mã bảo hành được xóa thành công.', { type: 'success' })
        setShowDeleteAccount(false)
        dispatch(getProductActivationList({ ...filter, page }))
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const onLockAccount = async () => {
    try {
      const res = await patchLockProductActivationAPI({
        id: selected.id,
        status: selected.status === 'temporary_deleted' ? 'inactivate' : 'temporary_deleted'
      })
      if (res) {
        toast(res.message, { type: 'success' })
        setShowLockAccount(false)
        dispatch(getProductActivationList({ ...filter, page }))
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const uploadFile = async (files) => {
    setLoadingImport(true)
    let formData = new FormData()
    formData.append('file', files[0])

    try {
      const res = await importFileAPI(url.IMPORT_PRODUCT_ACTIVATION, formData)
      if (res) {
        handleCheckUpload(res.data.data)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setLoadingImport(false)
    }

    setLoading(false)
  }

  const handleCheckUpload = async (data) => {
    try {
      const res = await checkUploadFileAPI(data)

      if (!res.data.queueFinish) {
        setTimeout(() => {
          handleCheckUpload(data)
        }, 2000)
      } else {
        toast('Hệ thống đang xử lý. Vui lòng kiểm tra thông tin tại màn hình Lịch sử import', { type: 'success' })
        dispatch(getProductActivationList())
        setLoadingImport(false)
        setShowImport(false)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setLoadingImport(false)
    }
  }

  const handleClickExport = async () => {
    setLoading(true)
    try {
      const res = await exportProductActivationAPI(filter)
      if (res?.data?.success === false) {
        toast(res?.message, { type: 'error' })
      } else {
        toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút.', {
          type: 'success'
        })
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
    setLoading(false)
  }

  const handleClickDownloadTemplate = async () => {
    try {
      const res = await downloadTemplateAPI({ type: 'product_activation' })
      const nameFile = `Bieu_mau_import_MBH.xlsx`
      const url = window.URL.createObjectURL(new Blob([res]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', nameFile) //or any other extension
      document.body.appendChild(link)
      link.click()
      toast('Tải biểu mẫu thành công!', { type: 'success' })
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const bodyNoti = (
    <Card className='bg-secondary-subtle'>
      <CardBody className=' p-2'>
        <p className='text-muted font-size-16 mb-1'>{PortfolioVN.importModal.note1}</p>
        <p className='text-muted font-size-16 mb-1'>{PortfolioVN.importModal.note2}</p>
        <p className='text-muted font-size-16 mb-0'>- Số lượng bản ghi tối đa / tệp: 30,000 bản ghi</p>
      </CardBody>
    </Card>
  )

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{ProductActivationVN.title}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{ProductActivationVN.list}</h5>
                      <div className='btn-group flex-wrap' data-toggle='buttons'>
                        <div className='flex-row-0'>
                          <PermissionWrapper
                            screenCode={screenCode}
                            code={PRODUCT_ACTIVATION_MANAGEMENT_CODE.PRODUCT_IMPORT_PRODUCT_ACTIVATION}
                          >
                            <button onClick={() => setShowImport(true)} className='btn btn-primary me-2 pe-4 mb-2'>
                              <i className='mdi mdi-database-import me-1 ms-2' />
                              {ProductActivationVN.import}
                            </button>
                          </PermissionWrapper>
                          <PermissionWrapper
                            screenCode={screenCode}
                            code={PRODUCT_ACTIVATION_MANAGEMENT_CODE.PRODUCT_EXPORT_PRODUCT_ACTIVATION}
                          >
                            <button onClick={() => handleClickExport()} className='btn btn-primary me-2 pe-4 mb-2'>
                              <i className='mdi mdi-database-export me-1 ms-2' />
                              {ProductActivationVN.export}
                            </button>
                          </PermissionWrapper>
                          <PermissionWrapper
                            screenCode={screenCode}
                            code={PRODUCT_ACTIVATION_MANAGEMENT_CODE.PRODUCT_CREATE_PRODUCT_ACTIVATION}
                          >
                            <button
                              onClick={() => {
                                setShowForm(true)
                                dispatch(setTypePopupProductActivation('create'))
                              }}
                              className='btn btn-primary me-2 pe-4 mb-2'
                            >
                              <i className='mdi mdi-plus me-1' />
                              {ProductActivationVN.add}
                            </button>
                          </PermissionWrapper>
                          <button
                            onClick={() => {
                              navigate('/manage-import-history')
                            }}
                            className='btn btn-primary me-2 pe-4 mb-2'
                          >
                            <i className='mdi mdi-history me-1' />
                            {'Lịch sử import'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <TableContainer
                      columns={permissionColumns}
                      data={productActivations}
                      isGlobalFilter={true}
                      setGlobalFilterUser={setGlobalFilterUser}
                      isProductActivationFilter={true}
                      placeholderSearch={ProductActivationVN.filter.placeholderSearch}
                      isPagination={true}
                      iscustomPageSizeOptions={false}
                      isShowingPageLength={false}
                      customPageSize={10}
                      tableClass='table-bordered align-middle nowrap mt-2'
                      paginationDiv='col-sm-12 col-md-7'
                      pagination='pagination justify-content-end pagination-rounded'
                      useAPIChangePaging
                      currentPage={page}
                      gotoPageCustom={goToPage}
                      total={total}
                      isSearchInput={false}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ConfirmModal
        show={showDeleteAccount}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-alert'></i>
          </div>
        }
        onConfirmClick={onDeleteAccount}
        onCloseClick={() => setShowDeleteAccount(false)}
        title='Xóa mã bảo hành'
        description={`Mã bảo hành bị xóa sẽ không thể kích hoạt. Xác nhận xóa Mã bảo hành?`}
      />
      <ConfirmModal
        show={showLockAccount}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-alert'></i>
          </div>
        }
        onConfirmClick={onLockAccount}
        onCloseClick={() => setShowLockAccount(false)}
        title={selected?.status !== 'temporary_deleted' ? 'Tạm khóa mã bảo hành' : 'Mở khóa mã bảo hành'}
        description={
          selected?.status !== 'temporary_deleted'
            ? `Mã bảo hành bị khóa sẽ không thể kích hoạt. Xác nhận khóa mã bảo hành?`
            : 'Xác nhận mở khóa mã bảo hành?'
        }
      />
      {showForm && (
        <AddEditProductActivation
          onCloseClick={() => {
            setShowForm(false)
          }}
          show={showForm}
          setPage={setPage}
        />
      )}
      {showImport && (
        <ImportModal
          onCloseClick={() => {
            setShowImport(false)
          }}
          show={showImport}
          onConfirmClick={uploadFile}
          loading={isLoadingImport}
          title={ProductActivationVN.importModal.title}
          onDownloadClick={handleClickDownloadTemplate}
          bodyNoti={bodyNoti}
        />
      )}
    </React.Fragment>
  )
}

export default ManageProductActivation
