import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Alert, Card, CardBody, Col, Container, Input, Label, Row, UncontrolledTooltip } from 'reactstrap'

//redux
import { useDispatch } from 'react-redux'
import withRouter from 'components/Common/withRouter'

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb'
import { ManageContentVN } from 'constants/vn'

// styles
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { getContentDetail, getReconcileProgramByArea } from 'store/actions'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import {
  putContentDetailAPI,
  postContentAPI,
  getProgramListAPI,
  getProgramDetailsAPI,
  getRankAllAPI,
  postImage,
  patchStatusContentAPI
} from 'helpers/backend_helper'
import { checkFileSizeValid, checkIsLocked, formateDate } from 'helpers/global_helper'
import { toast } from 'react-toastify'
import { MAX_LENGTH_NAME, typeAccountProgramOptions, TypeContentOptions, TypeProgramOptions } from 'constants/constants'

import { debounce, isEqual } from 'lodash'
import { Form, Image, Upload, Button, Spin, Tooltip, Space } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { FormInput } from 'components/Common/form-input/FormInput'

import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'
import dayjs from 'dayjs'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import ConfirmModal from 'components/Common/ConfirmModal'
import moment from 'moment'
import EditorCustom from 'components/Common/EditorCustom/EditorCustom'

import TableContainer from 'components/Common/TableContainer'
import Spinners from 'components/Common/Spinner'
import { Name, ReportCode, TotalCompleted, TotalUser } from './ReconcileProgramByAreaCol'
import { Link } from 'react-router-dom'
import ReconcileProgramByAreaFilter from './components/ReconcileProgramByAreaFilter'
import { TableRowSpan } from 'components/Common/TableCustom'

const ReconcileProgramByArea = (props) => {
  //meta title
  document.title = 'Content Detail | HPG'
  const params = props.router.params

  const stringUrl = params.id
  // const type = 'display'
  // // const id = paramsFromUrl['id']
  // const id = params.id

  // Initialize an empty object to store key-value stringUrl
  const paramsFromUrl = {}

  // Split the string by '&' to get individual key-value pairs
  const urlSplit = stringUrl.split('&')

  // Loop through each key-value pair
  urlSplit.forEach((pair) => {
    // Split each property by '=' to separate key and value
    const [key, value] = pair.split('=')
    // Store the key-value pair in the params object
    paramsFromUrl[key] = value
  })

  const type = paramsFromUrl['type']
  const id = paramsFromUrl['id']

  const [form] = Form.useForm()
  const watchForm = Form.useWatch([], form)
  const dispatch = useDispatch()
  const reconcileProgramByAreaState = (state) => state.ProgramBonusTempReducer
  const ContentDetailProperties = createSelector(reconcileProgramByAreaState, (contentReducer) => ({
    contents: contentReducer.reconcileProgramByAreas,
    loading: contentReducer.loading,
    total: contentReducer.total,
    value: contentReducer.value
  }))
  const { contents, loading, total, value } = useSelector(ContentDetailProperties)

  const [queryParams, setQueryParams] = useState({
    search: '',
    sortBy: []
  })

  const [filter, setFilter] = useState()
  const [page, setPage] = useState(1)
  const [isLoading, setLoading] = useState(loading)
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })

  const columns = useMemo(() => {
    if (type === 'display') {
      return [
        {
          Header: 'STT',
          accessor: 'stt',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return (page - 1) * 10 + cellProps.row.index + 1
          }
        },
        {
          Header: 'Miền',
          accessor: 'zone.name',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <Name {...cellProps} />
          }
        },
        {
          Header: 'Vùng',
          accessor: 'subZone.name',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <Name {...cellProps} />
          }
        },
        {
          Header: 'Tỉnh/ Thành phố',
          accessor: 'province.name',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <Name {...cellProps} />
          }
        },
        {
          Header: 'Quận/huyện',
          accessor: 'name',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <Name {...cellProps} />
          }
        },
        {
          Header: 'Số lượng tài khoản tham gia',
          accessor: 'totalUser',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <TotalUser {...cellProps} />
          }
        },
        {
          Header: 'Số lượng hoàn thiện trưng bày',
          accessor: 'totalCompleted',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <TotalCompleted {...cellProps} />
          }
        },
        {
          Header: 'Thao tác',
          accessor: 'action',
          disableFilters: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            let linkTo
            let linkToView
            //link đối soát
            if (value.configType == 'package') {
              if (value.type == 'accumulation' || value.type == 'activation') {
                linkTo = `/package-programming-by-area-and-reward-type/province_id=${cellProps.row.original.id}&id=${id}`
              } else if (value.type == 'display') {
                linkTo = `/display-programming-by-area-and-reward-type/province_id=${cellProps.row.original.id}&id=${id}`
              }
            } else {
              linkTo = `/programming-by-area-and-reward-type/province_id=${cellProps.row.original.id}&id=${id}`
            }
            //link view
            if (value.configType == 'package') {
              if (value.type == 'accumulation' || value.type == 'activation') {
                linkToView = `/package-programming-by-area-and-reward-type-view/province_id=${cellProps.row.original.id}&id=${id}`
              } else if (value.type == 'display') {
                linkToView = `/display-programming-by-area-and-reward-type-view/province_id=${cellProps.row.original.id}&id=${id}`
              }
            } else {
              linkToView = `/programming-by-area-and-reward-type-view/province_id=${cellProps.row.original.id}&id=${id}`
            }
            return (
              <ul className='list-unstyled hstack gap-1 mb-0'>
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <Link
                    to={linkToView}
                    className='btn btn-sm btn-soft-primary'
                    id={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    <i className='mdi mdi-eye-outline' />
                    <UncontrolledTooltip placement='top' target={`viewtooltip-${cellProps.row.original.id}`}>
                      Xem
                    </UncontrolledTooltip>
                  </Link>
                </li>

                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <Link
                    to={linkTo}
                    className='btn btn-sm btn-soft-primary'
                    id={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    <i className='mdi mdi-file-check' />
                    <UncontrolledTooltip placement='top' target={`viewtooltip-${cellProps.row.original.id}`}>
                      Đối soát
                    </UncontrolledTooltip>
                  </Link>
                </li>

                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <Link to={`#`} className='btn btn-sm btn-soft-info' id={`edittooltip-${cellProps.row.original.id}`}>
                    <i className='mdi mdi-download' />
                    <UncontrolledTooltip placement='top' target={`edittooltip-${cellProps.row.original.id}`}>
                      Download
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </ul>
            )
          }
        }
      ]
    } else {
      return [
        {
          Header: 'STT',
          accessor: 'stt',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return (page - 1) * 10 + cellProps.row.index + 1
          }
        },
        {
          Header: 'Miền',
          accessor: 'zone.name',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <Name {...cellProps} />
          }
        },
        {
          Header: 'Vùng',
          accessor: 'subZone.name',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <Name {...cellProps} />
          }
        },
        {
          Header: 'Tỉnh/ Thành phố',
          accessor: 'province.name',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <Name {...cellProps} />
          }
        },
        {
          Header: 'Quận/huyện',
          accessor: 'name',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <Name {...cellProps} />
          }
        },
        {
          Header: 'Số lượng tài khoản tham gia',
          accessor: 'totalUser',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <TotalUser {...cellProps} />
          }
        },
        {
          Header: 'Thao tác',
          accessor: 'action',
          disableFilters: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            let linkTo
            let linkToView
            if (value.configType == 'package') {
              if (value.type == 'accumulation' || value.type == 'activation') {
                linkTo = `/package-programming-by-area-and-reward-type/province_id=${cellProps.row.original.id}&id=${id}`
              } else if (value.type == 'display') {
                linkTo = `/display-programming-by-area-and-reward-type/province_id=${cellProps.row.original.id}&id=${id}`
              }
            } else {
              linkTo = `/programming-by-area-and-reward-type/province_id=${cellProps.row.original.id}&id=${id}`
            }

            if (value.configType == 'package') {
              if (value.type == 'accumulation' || value.type == 'activation') {
                linkToView = `/package-programming-by-area-and-reward-type-view/province_id=${cellProps.row.original.id}&id=${id}`
              } else if (value.type == 'display') {
                linkToView = `/display-programming-by-area-and-reward-type-view/province_id=${cellProps.row.original.id}&id=${id}`
              }
            } else {
              linkToView = `/programming-by-area-and-reward-type-view/province_id=${cellProps.row.original.id}&id=${id}`
            }

            return (
              <ul className='list-unstyled hstack gap-1 mb-0'>
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <Link
                    to={linkToView}
                    className='btn btn-sm btn-soft-primary'
                    id={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    <i className='mdi mdi-eye-outline' />
                    <UncontrolledTooltip placement='top' target={`viewtooltip-${cellProps.row.original.id}`}>
                      Xem
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <Link
                    to={linkTo}
                    className='btn btn-sm btn-soft-primary'
                    id={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    <i className='mdi mdi-file-check' />
                    <UncontrolledTooltip placement='top' target={`viewtooltip-${cellProps.row.original.id}`}>
                      Đối soát
                    </UncontrolledTooltip>
                  </Link>
                </li>

                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <Link to={`#`} className='btn btn-sm btn-soft-info' id={`edittooltip-${cellProps.row.original.id}`}>
                    <i className='mdi mdi-download' />
                    <UncontrolledTooltip placement='top' target={`edittooltip-${cellProps.row.original.id}`}>
                      Download
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </ul>
            )
          }
        }
      ]
    }
  }, [filter, page, contents])

  useEffect(() => {
    if (id) {
      dispatch(getReconcileProgramByArea({ id: id }))
    }
  }, [dispatch])

  const setGlobalFilterUser = async (data) => {
    setFilter({ ...data, sort_field: '', sort_order: '' })
    dispatch(getReconcileProgramByArea({ ...data, id: id, sort_field: '', sort_order: '' }))
    setPage(1)
  }

  const handleSort = (sortBy) => {
    if (sortBy.length > 0) {
      setFilter({ ...filter, sort_field: sortBy[0].id, sort_order: sortBy[0].desc ? 'desc' : 'asc' })
      dispatch(
        getReconcileProgramByArea({
          ...filter,
          id: id,
          sort_field: sortBy[0].id,
          sort_order: sortBy[0].desc ? 'desc' : 'asc'
        })
      )
    } else {
      setFilter({ ...filter, sort_field: '', sort_order: '' })
      dispatch(getReconcileProgramByArea({ ...filter, id: id, sort_field: '', sort_order: '' }))
    }
  }

  const handleFilter = (filterValue) => {
    setFilter({ ...filterValue, sort_field: '', sort_order: '' })
    dispatch(getReconcileProgramByArea({ ...filterValue, id: id, sort_field: '', sort_order: '' }))
    setPage(1)
  }

  const goToPage = (page) => {
    // setPage(page)
    // handleGetListTable({ ...filter, page })
    // setFilter({ ...filter, page })
    setFilter({ ...filter, sort_field: '', sort_order: '', page: page })
    setPage(page)
    dispatch(getReconcileProgramByArea({ ...filter, id: id, sort_field: '', sort_order: '', page: page }))
  }

  return (
    <>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <Breadcrumb title={'Đối soát thưởng'} breadcrumbItem={'Đối soát thưởng'} />
          {/* <h4 className=' mb-sm-0 font-size-18 pb-4'>Đối soát thưởng</h4> */}
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody>
                    <Space size='middle' direction='vertical' className='w-100'>
                      <ReconcileProgramByAreaFilter handleFilter={handleFilter} />
                      <div className='d-flex flex-row '>
                        <div className='mb-xxl-0 me-3 mt-2'>
                          <h5 className='mb-0 card-title flex-grow-1'>{'Danh sách báo cáo theo khu vực'}</h5>
                        </div>
                        <div className='mb-xxl-0'>
                          {/* <h5>{programingCode}</h5> */}
                          <button className='btn btn-info btn-rounded me-2'>{value?.code}</button>
                        </div>
                      </div>
                      <TableRowSpan
                        columns={columns}
                        data={contents}
                        pagination={pagination}
                        setPagination={setPagination}
                        gotoPageCustom={goToPage}
                        currentPage={page}
                        total={total}
                        isShowTotal={true}
                      />
                    </Space>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      {/* {showModal && <ImageGalleryModal photos={photos} show={showModal} onClose={closeModal} />} */}
    </>
  )
}

export default withRouter(ReconcileProgramByArea)
