import { Form, Radio, Select } from 'antd'
import { FormDateMultiple } from 'components/Common/form-date-multiple/FormDateMultiple'
import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'
import { FormInputNumber } from 'components/Common/form-input-number/FormInputNumber'
import { FormInput } from 'components/Common/form-input/FormInput'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { MAX_LENGTH_100, typeAccountProgramOptions, TypeProgramOptions } from 'constants/constants'
import { ManageProgramVN } from 'constants/vn'
import dayjs from 'dayjs'
import { checkFileSizeValid } from 'helpers/global_helper'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Col, Label, Row } from 'reactstrap'
import { createSelector } from 'reselect'
import { getAccountRankListAPI, importFileAPI } from 'helpers/backend_helper'
import * as url from 'helpers/url_helper'

const ConditionTab = ({
  watchForm,
  form,
  industry,
  industrySector,
  category,
  model,
  channel,
  rank,
  type,
  setLoading,
  parallelProgram
}) => {
  // const type = "create"
  const isEdit = type !== 'view'

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    province: app.province,
    district: app.district,
    ward: app.ward,
    warehouseZone: app.zone,
    subzone: app.subzone
  }))
  const { province, district, ward, warehouseZone, subzone } = useSelector(LayoutProperties)

  const uploadRef = useRef(null)
  const [industrySectorSelected, setIndustrysectorSelected] = useState([])
  const [applyClientTypeSelected, setApplyClientTypeSelected] = useState([])
  const [applyWardSelected, setApplyWardSelected] = useState([])
  const [accountRank, setAccountRank] = useState([])

  useEffect(() => {
    form.setFieldValue('category', undefined)
    form.setFieldValue('model', undefined)
    getDataOptions()
  }, [industrySectorSelected])

  useEffect(() => {
    form.setFieldValue('rank', undefined)
    getDataOptions()
  }, [applyClientTypeSelected])

  useEffect(() => {
    getDataOptions()
  }, [applyWardSelected])

  const handleUploadClick = () => {
    uploadRef.current?.click()
  }

  const hanleFileChange = (e) => {
    const file = e?.target?.files[0]
    const fileValid = checkFileSizeValid(file)
    if (fileValid) {
      handleUploadFile(file)
    } else {
      toast('Ảnh vượt quá dung lượng cho phép (5MB). Vui lòng chọn ảnh khác', {
        type: 'error'
      })
    }
  }

  const getDataOptions = async () => {
    try {
      const resAccountRank = await getAccountRankListAPI({
        industry_sector_ids: industrySectorSelected,
        clients: applyClientTypeSelected,
        district_ids: Array.from(new Set(applyWardSelected))
      })
      console.log('resAccountRank?.data', resAccountRank?.data)
      setAccountRank(resAccountRank?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleUploadFile = async (file) => {
    setLoading(true)
    let formData = new FormData()
    formData.append('file', file)

    try {
      const res = await importFileAPI(url.IMPORT_PROGRAM_PACKAGE, formData)
      if (res) {
        console.log('resss', res)
        toast('Upload file thành công', { type: 'success' })
        form.setFieldValue('file', res.data.data.path)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
    setLoading(false)
  }

  const industryOptions = useMemo(
    () => [{ label: 'Tất cả', value: 'all' }].concat(industry?.map((e) => ({ label: e.name, value: e.id }))),
    [industry]
  )
  const industrySectorOptions = useMemo(() => {
    const listFilter = industrySector
      ?.filter((e) => watchForm.industry?.includes(e.parentId))
      ?.map((e) => ({ label: e.name, value: e.id }))
    return listFilter.length > 0 ? [{ label: 'Tất cả', value: 'all' }].concat(listFilter) : []
  }, [industrySector, watchForm])

  const categoryOptions = useMemo(() => {
    const listFilter = category
      ?.filter((e) => watchForm.industrySector?.includes(e.parentId))
      ?.map((e) => ({ label: e.name, value: e.id }))
    return listFilter.length > 0 ? [{ label: 'Tất cả', value: 'all' }].concat(listFilter) : []
  }, [category, watchForm])

  const modelOptions = useMemo(() => {
    const listFilter = model
      ?.filter((e) => watchForm.category?.includes(e.parentId))
      ?.map((e) => {
        if (watchForm?.type === 'accumulation' && e.isAccumulation) {
          return { label: e.name, value: e.id }
        }

        if (watchForm?.type === 'activation' && e.isActivated) {
          return { label: e.name, value: e.id }
        }

        if (watchForm?.type === 'display') {
          return { label: e.name, value: e.id }
        }
      })
    const list = listFilter?.filter((e) => e !== undefined)
    return list.length > 0 ? [{ label: 'Tất cả', value: 'all' }].concat(list) : []
  }, [model, watchForm])

  const channelOptions = useMemo(
    () => [{ label: 'Tất cả', value: 'all' }].concat(channel?.map((e) => ({ label: e.name, value: e.id }))),
    [channel]
  )
  const warehouseZoneOptions = useMemo(
    () => [{ label: 'Tất cả', value: 'all' }].concat(warehouseZone?.map((e) => ({ label: e.name, value: e.id }))),
    [warehouseZone]
  )
  // const rankOptions = useMemo(() => [{ label: "Tất cả", value: "all" }].concat(rank?.map(e => ({ label: e.name, value: e.id }))), [rank])
  const rankOptions = useMemo(() => {
    const listFilter = rank
      .filter((e) => watchForm.applyClientType?.includes(e.client))
      ?.map((e) => ({ label: e.name, value: e.id }))
    return listFilter.length > 0 ? [{ label: 'Tất cả', value: 'all' }].concat(listFilter) : []
  }, [rank, watchForm])

  const subzoneOptions = useMemo(() => {
    const listFilter = subzone
      ?.filter((e) => watchForm?.zone?.includes(e.zoneId))
      ?.map((e) => ({ label: e.name, value: e.id }))
    return listFilter?.length > 0 ? [{ label: 'Tất cả', value: 'all' }].concat(listFilter) : []
  }, [subzone, watchForm])

  const provinceOptions = useMemo(() => {
    const listFilter = province
      ?.filter((e) => watchForm?.subzone?.includes(e.subZoneId))
      ?.map((e) => ({ label: e.name, value: e.id }))
    return listFilter.length > 0 ? [{ label: 'Tất cả', value: 'all' }].concat(listFilter) : []
  }, [province, watchForm])

  const districtOptions = useMemo(() => {
    const listFilter = district
      ?.filter((e) => watchForm?.province?.includes(e.parentId))
      ?.map((e) => ({ label: e.name, value: e.id }))
    return listFilter.length > 0 ? [{ label: 'Tất cả', value: 'all' }].concat(listFilter) : []
  }, [district, watchForm])

  const wardOptions = useMemo(() => {
    const listFilter = ward
      ?.filter((e) => watchForm?.district?.includes(e.parentId))
      ?.map((e) => ({ label: e.name, value: e.id }))
    return listFilter.length > 0 ? [{ label: 'Tất cả', value: 'all' }].concat(listFilter) : []
  }, [ward, watchForm])
  const parallelProgramOptions = useMemo(
    () => [{ label: 'Tất cả', value: 'all' }].concat(parallelProgram?.map((e) => ({ label: e.name, value: e.id }))),
    [parallelProgram]
  )
  const accountRankOptions = useMemo(
    () =>
      [{ label: 'Tất cả', value: 'all' }].concat(
        accountRank?.map((e) => ({ label: e.industrySectorName + ' - ' + e.rankName, value: e.id }))
      ),
    [accountRank]
  )

  return (
    <div>
      <Row>
        <Col sm='6'>
          <Label className='form-label'>
            {ManageProgramVN.form.type.label}
            {type !== 'view' && <a className='text-danger'>*</a>}
          </Label>
          <Form.Item name='type' rules={[{ required: true, message: 'Vui lòng chọn loại chương trình' }]}>
            <FormSelect
              options={TypeProgramOptions}
              placeholder={ManageProgramVN.form.type.placeholder}
              onChange={(value) => {
                if (value === 'display') {
                  form.setFieldValue('programConfig', 'package')
                }
                form.setFieldValue('industry', undefined)
                form.setFieldValue('industrySector', undefined)
                form.setFieldValue('category', undefined)
                form.setFieldValue('model', undefined)
                // form.setFieldsValue("productRewardConditions", undefined)
                const data = form.getFieldsValue('productRewardConditions')
                if (data?.packages) {
                  if (data?.packages.length > 0) {
                    data?.packages.map((itemPackage, indexPackage) => {
                      if (itemPackage?.modelPackages.length > 0) {
                        itemPackage?.modelPackages.map((itemModelPackage, indexModelPackage) => {
                          form.setFieldValue(
                            ['packages', indexPackage, 'modelPackages', indexModelPackage, 'industryIds'],
                            undefined
                          )
                          form.setFieldValue(
                            ['packages', indexPackage, 'modelPackages', indexModelPackage, 'industrySectorIds'],
                            undefined
                          )
                          form.setFieldValue(
                            ['packages', indexPackage, 'modelPackages', indexModelPackage, 'categoryIds'],
                            undefined
                          )
                          form.setFieldValue(
                            ['packages', indexPackage, 'modelPackages', indexModelPackage, 'modelIds'],
                            undefined
                          )
                        })
                      }
                    })
                  }
                }
              }}
              disabled={!isEdit}
            />
          </Form.Item>
        </Col>
        <Col sm='6'>
          <div className='d-flex flex-row' style={{ height: '25%' }}>
            <Col sm='6'>
              <Label className='form-label  me-5'>
                {ManageProgramVN.form.applyParallel.label}
                {type !== 'view' && <a className='text-danger'>*</a>}
              </Label>
            </Col>
            <Col sm='6'>
              <Form.Item name='applyParallel'>
                <Radio.Group
                  options={ManageProgramVN.form.applyParallel.options}
                  optionType='default'
                  disabled={!isEdit}
                />
              </Form.Item>
            </Col>
          </div>
          {watchForm?.applyParallel === true && (
            <Col sm='12' style={{ marginBottom: 'none', paddingBottom: 'none' }}>
              <Form.Item name='parallelProgram' rules={[{ required: true, message: 'Vui lòng chọn ngành' }]}>
                <FormSelect
                  mode='multiple'
                  showSearch
                  allowClear
                  maxTagTextLength={5}
                  maxTagCount={2}
                  options={parallelProgramOptions}
                  onSelect={(value) => {
                    const all = industry?.map((e) => e.id)
                    if (value && value === 'all') {
                      return form.setFieldValue('parallelProgram', [...all])
                    }
                  }}
                  // placeholder={ManageProgramVN.form.industry.placeholder}
                  disabled={!isEdit}
                />
              </Form.Item>
            </Col>
          )}
        </Col>
      </Row>
      <Row>
        <Col sm='6'>
          <Label className='form-label'>
            {ManageProgramVN.form.name.label}
            {type !== 'view' && <a className='text-danger'>*</a>}
          </Label>
          <Form.Item name='name' rules={[{ required: true, message: 'Vui lòng nhập tên chương trình' }]}>
            <FormInput
              placeholder={ManageProgramVN.form.name.placeholder}
              disabled={!isEdit}
              maxLength={MAX_LENGTH_100}
            />
          </Form.Item>
        </Col>
        <Col>
          <div className='d-flex flex-row'>
            <Col sm='6'>
              <Label className='form-label me-5'>
                {ManageProgramVN.form.applyTime.label}
                {type !== 'view' && <a className='text-danger'>*</a>}
              </Label>
            </Col>
            <Col sm='6'>
              <Form.Item className='mb-0' name='applyTime'>
                <Radio.Group
                  rootClassName='pe-4'
                  options={ManageProgramVN.form.applyTime.options}
                  optionType='default'
                  onChange={() => {
                    form.setFieldValue('applyDates', undefined)
                  }}
                  disabled={!isEdit}
                />
              </Form.Item>
            </Col>
          </div>
          {watchForm?.applyTime === 'range_time' ? (
            <Form.Item name='applyDates' rules={[{ required: true, message: 'Vui lòng chọn thời gian áp dụng' }]}>
              <FormDateRange disabledDate={(current) => dayjs().isAfter(current, 'day')} disabled={!isEdit} />
            </Form.Item>
          ) : (
            <Form.Item name='applyDates' rules={[{ required: true, message: 'Vui lòng chọn thời gian áp dụng' }]}>
              <FormDateMultiple disabled={!isEdit} />
            </Form.Item>
          )}
        </Col>
      </Row>
      <h5>{ManageProgramVN.form.productApply}</h5>
      <Row className='mt-4'>
        <Col sm='3'>
          <Label className='form-label'>
            {ManageProgramVN.form.industry.label}
            {type !== 'view' && <a className='text-danger'>*</a>}
          </Label>
          <Form.Item name='industry' rules={[{ required: true, message: 'Vui lòng chọn ngành' }]}>
            <FormSelect
              mode='multiple'
              showSearch
              allowClear
              maxTagTextLength={5}
              maxTagCount={2}
              options={industryOptions}
              onChange={() => {
                form.setFieldValue('industrySector', undefined)
                form.setFieldValue('category', undefined)
                form.setFieldValue('model', undefined)
              }}
              onSelect={(value) => {
                const all = industry?.map((e) => e.id)
                if (value && value === 'all') {
                  return form.setFieldValue('industry', [...all])
                }
              }}
              placeholder={ManageProgramVN.form.industry.placeholder}
              disabled={!isEdit}
            />
          </Form.Item>
        </Col>
        <Col sm='3'>
          <Label className='form-label'>
            {'Ngành hàng'}
            {type !== 'view' && <a className='text-danger'>*</a>}
          </Label>
          <Form.Item name='industrySector' rules={[{ required: true, message: 'Vui lòng chọn ngành hàng' }]}>
            <FormSelect
              mode='multiple'
              showSearch
              allowClear
              maxTagTextLength={5}
              maxTagCount={2}
              options={industrySectorOptions}
              onChange={(value) => {
                const all = industrySector?.filter((e) => watchForm.industry?.includes(e.parentId))?.map((e) => e.id)
                if (value && value.includes('all')) {
                  setIndustrysectorSelected(all)
                  form.setFieldValue('industrySector', [...all])
                } else {
                  if (!industrySectorSelected.includes(value)) {
                    setIndustrysectorSelected(value)
                    form.setFieldValue('industrySector', value)
                  }
                }
              }}
              placeholder={ManageProgramVN.form.industrySector.placeholder}
              disabled={!isEdit}
            />
          </Form.Item>
        </Col>
        <Col sm='3'>
          <Label className='form-label'>
            {ManageProgramVN.form.category.label}
            {type !== 'view' && <a className='text-danger'>*</a>}
          </Label>
          <Form.Item name='category' rules={[{ required: true, message: 'Vui lòng chọn nhóm sản phẩm' }]}>
            <FormSelect
              mode='multiple'
              showSearch
              allowClear={<i className='mdi mdi-close-circle-outline mdi-24px ' />}
              maxTagTextLength={5}
              maxTagCount={2}
              options={categoryOptions}
              onChange={() => {
                form.setFieldValue('model', undefined)
              }}
              onSelect={(value) => {
                const all = category?.filter((e) => watchForm.industrySector?.includes(e.parentId))?.map((e) => e.id)
                if (value && value === 'all') {
                  return form.setFieldValue('category', [...all])
                }
              }}
              placeholder={ManageProgramVN.form.category.placeholder}
              disabled={!isEdit}
            />
          </Form.Item>
        </Col>
        <Col sm='3'>
          <Label className='form-label'>
            {ManageProgramVN.form.model.label}
            {type !== 'view' && <a className='text-danger'>*</a>}
          </Label>
          <Form.Item name='model' rules={[{ required: true, message: 'Vui lòng chọn model sản phẩm' }]}>
            <FormSelect
              mode='multiple'
              showSearch
              allowClear
              maxTagTextLength={5}
              maxTagCount={2}
              options={modelOptions}
              onSelect={(value) => {
                const all = model?.filter((e) => watchForm.category?.includes(e.parentId))?.map((e) => e.id)
                if (value && value === 'all') {
                  return form.setFieldValue('model', [...all])
                }
              }}
              placeholder={ManageProgramVN.form.model.placeholder}
              disabled={!isEdit}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col sm='3'>
          <Label className='form-label'>
            {ManageProgramVN.form.channel.label}

            {type !== 'view' && <a className='text-danger'>*</a>}
          </Label>
          <Form.Item name='channel' rules={[{ required: true, message: 'Vui lòng chọn kênh' }]}>
            <FormSelect
              mode='multiple'
              allowClear
              maxTagTextLength={5}
              maxTagCount={2}
              options={channelOptions}
              onSelect={(value) => {
                const all = channel?.map((e) => e.id)
                if (value && value === 'all') {
                  return form.setFieldValue('channel', [...all])
                }
              }}
              placeholder={ManageProgramVN.form.channel.placeholder}
              disabled={!isEdit}
            />
          </Form.Item>
        </Col>
        <Col sm='3'>
          <Label className='form-label'>
            {ManageProgramVN.form.warehouseZone.label}
            {type !== 'view' && <a className='text-danger'>*</a>}
          </Label>
          <Form.Item name='warehouseZone' rules={[{ required: true, message: 'Vui lòng chọn miền' }]}>
            <FormSelect
              mode='multiple'
              allowClear
              maxTagTextLength={5}
              maxTagCount={2}
              disabled={!isEdit}
              options={warehouseZoneOptions}
              onSelect={(value) => {
                const all = warehouseZone?.map((e) => e.id)
                if (value && value === 'all') {
                  return form.setFieldValue('warehouseZone', [...all])
                }
              }}
              placeholder={ManageProgramVN.form.warehouseZone.label}
            />
          </Form.Item>
        </Col>
        <Col sm='3'>
          <Label className='form-label'>{ManageProgramVN.form.warehouseDate.label}</Label>
          <Form.Item name='warehouseDate'>
            <FormDateRange disabled={!isEdit} />
          </Form.Item>
        </Col>
      </Row>

      <h5>{ManageProgramVN.form.zoneApply}</h5>
      <Row className='mt-4'>
        <Col sm='3'>
          <Label className='form-label'>
            {ManageProgramVN.form.zone.label}
            {type !== 'view' && <a className='text-danger'>*</a>}
          </Label>
          <Form.Item name='zone' rules={[{ required: true, message: 'Vui lòng chọn miền' }]}>
            <FormSelect
              mode='multiple'
              showSearch
              allowClear
              maxTagTextLength={5}
              maxTagCount={2}
              options={warehouseZoneOptions}
              onChange={() => {
                form.setFieldValue('subzone', undefined)
                form.setFieldValue('province', undefined)
                form.setFieldValue('district', undefined)
                form.setFieldValue('ward', undefined)
              }}
              onSelect={(value) => {
                const all = warehouseZone?.map((e) => e.id)
                if (value && value === 'all') {
                  return form.setFieldValue('zone', [...all])
                }
              }}
              placeholder={ManageProgramVN.form.zone.placeholder}
              disabled={!isEdit}
            />
          </Form.Item>
        </Col>
        <Col sm='3'>
          <Label className='form-label'>
            {ManageProgramVN.form.subzone.label}
            {type !== 'view' && <a className='text-danger'>*</a>}
          </Label>
          <Form.Item name='subzone' rules={[{ required: true, message: 'Vui lòng chọn vùng' }]}>
            <FormSelect
              mode='multiple'
              showSearch
              allowClear
              maxTagTextLength={5}
              maxTagCount={2}
              options={subzoneOptions}
              onChange={() => {
                form.setFieldValue('province', undefined)
                form.setFieldValue('district', undefined)
                form.setFieldValue('ward', undefined)
              }}
              onSelect={(value) => {
                const all = subzone?.filter((e) => watchForm.zone?.includes(e.zoneId))?.map((e) => e.id)
                if (value && value === 'all') {
                  return form.setFieldValue('subzone', [...all])
                }
              }}
              placeholder={ManageProgramVN.form.subzone.placeholder}
              disabled={!isEdit}
            />
          </Form.Item>
        </Col>
        <Col sm='3'>
          <Label className='form-label'>
            {ManageProgramVN.form.province.label}
            {type !== 'view' && <a className='text-danger'>*</a>}
          </Label>
          <Form.Item name='province' rules={[{ required: true, message: 'Vui lòng chọn tỉnh/thành phố' }]}>
            <FormSelect
              mode='multiple'
              showSearch
              allowClear={<i className='mdi mdi-close-circle-outline mdi-24px ' />}
              maxTagTextLength={5}
              maxTagCount={2}
              options={provinceOptions}
              onChange={() => {
                form.setFieldValue('district', undefined)
                form.setFieldValue('ward', undefined)
              }}
              onSelect={(value) => {
                const all = province?.filter((e) => watchForm.subzone?.includes(e.subZoneId))?.map((e) => e.id)
                if (value && value === 'all') {
                  return form.setFieldValue('province', [...all])
                }
              }}
              placeholder={ManageProgramVN.form.province.placeholder}
              disabled={!isEdit}
            />
          </Form.Item>
        </Col>
        <Col sm='3'>
          <Label className='form-label'>
            {'Quận/ huyện'}
            {type !== 'view' && <a className='text-danger'>*</a>}
          </Label>
          <Form.Item name='district' rules={[{ required: true, message: 'Vui lòng chọn quận/huyện' }]}>
            <FormSelect
              mode='multiple'
              showSearch
              allowClear
              maxTagTextLength={5}
              maxTagCount={2}
              options={districtOptions}
              onChange={() => {
                form.setFieldValue('ward', undefined)
              }}
              onSelect={(value) => {
                const all = district?.filter((e) => watchForm.province?.includes(e.parentId))?.map((e) => e.id)
                if (value && value === 'all') {
                  return form.setFieldValue('district', [...all])
                }
              }}
              placeholder={ManageProgramVN.form.district.placeholder}
              disabled={!isEdit}
            />
          </Form.Item>
        </Col>
        <Col sm='3'>
          <Label className='form-label'>
            {'Phường/ xã'}
            {type !== 'view' && <a className='text-danger'>*</a>}
          </Label>
          <Form.Item name='ward' rules={[{ required: true, message: 'Vui lòng chọn phường/xã' }]}>
            <FormSelect
              mode='multiple'
              showSearch
              allowClear
              maxTagTextLength={5}
              maxTagCount={2}
              options={wardOptions}
              onSelect={(value) => {
                const all = ward?.filter((e) => watchForm.district?.includes(e.parentId))?.map((e) => e.id)
                if (value && value === 'all') {
                  setApplyWardSelected(
                    ward?.filter((e) => watchForm.district?.includes(e.parentId))?.map((e) => e.parentId)
                  )
                  return form.setFieldValue('ward', [...all])
                } else {
                  form.setFieldValue('ward', value)
                  setApplyWardSelected(ward?.filter((e) => watchForm.ward?.includes(value))?.map((e) => e.parentId))
                }
              }}
              placeholder={ManageProgramVN.form.ward.placeholder}
              disabled={!isEdit}
            />
          </Form.Item>
        </Col>
      </Row>

      <h5>{ManageProgramVN.form.accountApply}</h5>
      <Row className='mt-3'>
        <Col sm='3'>
          <Label className='form-label'>
            {'Loại tài khoản'}

            {type !== 'view' && <a className='text-danger'>*</a>}
          </Label>
          <Form.Item name='applyClientType' rules={[{ required: true, message: 'Vui lòng chọn loại tài khoản' }]}>
            <FormSelect
              mode='multiple'
              allowClear
              maxTagTextLength={5}
              maxTagCount={2}
              options={
                watchForm?.type !== 'accumulation'
                  ? [
                      {
                        label: 'Tất cả',
                        value: 'all'
                      },
                      {
                        label: 'Cửa hàng',
                        value: 'user'
                      },
                      {
                        label: 'Kĩ thuật viên',
                        value: 'technician'
                      }
                    ]
                  : [
                      {
                        label: 'Tất cả',
                        value: 'all'
                      },
                      {
                        label: 'Cửa hàng',
                        value: 'user'
                      }
                    ]
              }
              onChange={(value) => {
                const all = watchForm?.type !== 'accumulation' ? ['user', 'technician'] : ['user']
                if (value && value.includes('all')) {
                  setApplyClientTypeSelected(all)
                  form.setFieldValue('applyClientType', [...all])
                } else {
                  if (!applyClientTypeSelected.includes(value)) {
                    setApplyClientTypeSelected(value)
                    form.setFieldValue('applyClientType', value)
                  }
                }
              }}
              placeholder={ManageProgramVN.form.applyClientType.placeholder}
              disabled={!isEdit}
            />
          </Form.Item>
        </Col>
        <Col sm='6'>
          <Label className='form-label'>{'Hạng tài khoản'}</Label>
          <Form.Item
            name='rank'
            // rules={[{ required: true, message: "Vui lòng chọn hạng tài khoản" }]}
          >
            <FormSelect
              mode='multiple'
              allowClear
              maxTagTextLength={5}
              maxTagCount={2}
              disabled={!isEdit}
              options={accountRankOptions}
              onSelect={(value) => {
                const all =
                  accountRankOptions.length > 0 &&
                  accountRankOptions?.filter((i) => i.value != 'all').map((e) => e.value)
                if (value && value === 'all') {
                  return form.setFieldValue('rank', [...all])
                }
              }}
              placeholder={ManageProgramVN.form.rank.label}
            />
          </Form.Item>
        </Col>
        <Col sm='3'>
          <Label className='form-label'>{ManageProgramVN.form.createAtAccount.label}</Label>
          <Form.Item name='createAtAccount'>
            <FormDateRange disabled={!isEdit} />
          </Form.Item>
        </Col>
      </Row>

      {watchForm?.type === 'display' && (
        <>
          <h5>{'Điều kiện trưng bày'}</h5>
          <Row>
            <Col sm='3'>
              <Label className='form-label'>
                {'Thời gian hoàn thiện hồ sơ trưng bày'}
                {type !== 'view' && <a className='text-danger'>*</a>}
              </Label>
              <Form.Item name='timeComplete' rules={[{ required: true, message: 'Vui lòng nhập thời gian' }]}>
                <FormInputNumber
                  placeholder={ManageProgramVN.form.timeComplete.placeholder}
                  type={type}
                  disabled={type === 'view'}
                  // maxLength={MAX_LENGTH_100}
                  max={999}
                  min={1}
                />
              </Form.Item>
            </Col>
            <Col sm='9' className='d-flex align-items-end ps-4'>
              <Form.Item name='file'>
                <div className='d-flex flex-col'>
                  <Link to='#' onClick={handleUploadClick} className='link-offset-1 text-decoration-underline'>
                    {ManageProgramVN.form.upload}
                  </Link>
                  <FormInput style={{ display: 'none' }} />
                  {watchForm?.file && (
                    <div className='d-flex flex-col ms-4'>
                      <i className='mdi mdi-attachment' style={{ transform: 'rotate(-90deg)' }}></i>
                      <div className='link-offset-1 text-decoration-underline'>{watchForm?.file}</div>
                    </div>
                  )}
                </div>
              </Form.Item>
              <input
                id='exampleFile'
                type='file'
                ref={uploadRef}
                accept='.doc, .docx'
                onChange={hanleFileChange}
                className='d-none'
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  )
}
export default ConditionTab
